import Image from "next/image";
import errorCat from '../assets/errorCat.svg';
import {useRouter} from "next/router";

import { GetStaticProps } from "next";

export const getStaticProps: GetStaticProps = async (context) => {
    return {
        props: {
            title: 'Whoops! We can\'t find that page',
            description: 'this is a description'
        }
    };
}

const Custom404 = () => {

    const router = useRouter();

    return (
        <div className="h-screen -mt-24 w-screen flex items-center">
            <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-white">
                <div className="max-w-md">
                    <div className="text-5xl font-dark font-bold">404</div>
                    <p className="text-2xl my-3 md:text-3xl font-light leading-normal">Sorry we couldn&apos;t find this page.</p>
                    <p className="mb-8">But dont worry, there is still plenty to do.</p>
                    <button className="p-3 px-5 bg-primary text-white rounded hover:bg-primary-600" onClick={() => router.back()}>Go back</button>
                </div>
                <div className="max-w-lg">
                    <Image src={errorCat} alt={"It's the error cat!"} />
                </div>

            </div>
        </div>
    )
}

export default Custom404
